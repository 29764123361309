import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import { store } from '@/store'
import '@/plugins/axios'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config.js'
import '@/../css/base.css'
import Header from './components/global/Header.vue'
import HeaderMobile from './components/global/HeaderMobile.vue'
import Search from './components/global/MainSearch.vue'
import MobileSearch from './components/global/MobileMainSearch.vue'
import Footer from './components/global/Footer.vue'
import MobileNav from './components/global/MobileNav.vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App)
app.component('Header', Header)
app.component('HeaderMobile', HeaderMobile)
app.component('Search', Search)
app.component('MobileSearch', MobileSearch)
app.component('MobileNav', MobileNav)
app.component('Footer', Footer)
app.use(router)
app.use(store)
app.use(VueGoogleMaps, {
    load: {
        key: appConfigs.GOOGLE_MAPS_API_KEY,
        libraries: ["geocoding", "places"],
        v: 3.57
    },
    autobindAllEvents: true,
})
app.mount('#app')