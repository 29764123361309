<template>
    <div class="w-full py-10 md:py-32 bg-1B3B3F" v-if="showFooter" id="footer">
        <div class="max-w-1141px mx-auto w-full relative mb-9">
            <div class="flex items-center px-4">
                <div class="logo">
                    <router-link  to="/"><img src="/images/yougo-white-logo.svg"/></router-link>
                </div>
            </div>
        </div>
        <div class="max-w-1141px mx-auto w-full relative">
            <div class="flex items-center px-4 mb-1.5">
               <div class="text-white text-xs font-normal"> © {{ renderYear }} YouGo LLC All rights reserved.</div>
            </div>
            <div class="flex flex-col sm:flex-row items-center px-4 w-full gap-10 text-center">
                <div class="text-15px text-white font-bold flex flex-wrap gap-x-4 max-w-xl" v-if="isLoggedIn">
                    <router-link to="/user-agreement" class="underline hover:no-underline inline-block transition-all">User Agreement</router-link>
                    <router-link to="/guide-agreement" class="underline hover:no-underline inline-block transition-all">Guide Agreement</router-link>
                    <router-link to="/legal" class="underline hover:no-underline inline-block transition-all">Legal Hub</router-link>
                </div>
                <div v-else class="text-15px text-white font-bold flex flex-wrap gap-x-4 max-w-xl">
                    <router-link to="/terms-of-use" class="underline hover:no-underline inline-block transition-all">Terms of Use</router-link>
                    <router-link to="/privacy-policy" class="underline hover:no-underline inline-block transition-all">Privacy Policy</router-link>
                    <router-link to="/cookie-policy" class="underline hover:no-underline inline-block transition-all">Cookie Policy</router-link>
                    <router-link to="/disclaimer" class="underline hover:no-underline inline-block transition-all">Disclaimer</router-link>
                    <router-link to="/intellectual-property-policy" class="underline hover:no-underline inline-block transition-all">Intellectual Property Policy</router-link>
                    <router-link to="/accessibility-statement" class="underline hover:no-underline inline-block transition-all">Accessibility Statement</router-link>
                    <router-link to="/" class="underline hover:no-underline inline-block transition-all">Contact us</router-link>
                </div>
                <div class="flex sm:ml-auto items-center gap-x-7">
                    <a href="/" class="text-white hover:text-opacity-70 transition-all">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10.001 0.0610343C4.478 0.0610348 3.91304e-07 4.53703 8.74228e-07 10.061C1.31073e-06 15.054 3.661 19.193 8.445 19.94L8.445 12.95L5.89 12.95L5.89 10.061L8.446 10.061L8.447 7.85803C8.447 5.35203 9.931 3.96203 12.216 3.96203C13.311 3.96203 14.446 4.17203 14.446 4.17203L14.446 6.61703L13.193 6.61703C11.951 6.61703 11.554 7.39403 11.554 8.18503L11.557 10.061L14.334 10.061L13.89 12.95L11.557 12.95L11.557 19.94C16.34 19.192 20 15.054 20 10.061C20 4.53703 15.523 0.0610338 10.001 0.0610343Z" fill="currentColor"/>
                        </svg>
                    </a>
                    <a href="/" class="text-white hover:text-opacity-70 transition-all">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                            <path d="M1.28511e-06 15.1C2.2 15.3 4.3 14.6 5.9 13.201C4.7 13.1 2.3 10.801 1.9 10.301L3.5 10.301C1.6 9.80102 0.300001 8.10102 0.3 6.20102C0.9 6.30002 1.5 6.50002 2 6.70002L2.2 6.70002C0.7 5.30002 0.2 3.00002 1.1 1.10002C3.2 3.70002 6.4 5.30002 9.7 5.50002L9.7 4.40002C9.8 2.20002 11.7 0.400023 13.9 0.400023C14.8 0.400023 15.6 0.700023 16.3 1.20002C16.7 1.50002 17.1 1.50002 17.5 1.40002L19.4 0.700023C19 1.60002 18.5 2.30002 17.8 3.00002C18.401 2.90002 19.1 2.60002 19.7 2.50002L20 2.70002C19.4 3.20002 18.8 3.80002 18.3 4.20002C18.2 4.50002 18.1 4.90002 18.1 5.30002C18.1 7.20002 17.6 9.10002 16.8 10.901C15.8703 12.6123 14.5376 14.0712 12.9171 15.1513C11.2966 16.2315 9.43722 16.9003 7.5 17.1C5.1 17.3 2.6 16.901 0.500001 15.8L1.28511e-06 15.1Z" fill="currentColor"/>
                        </svg>
                    </a>
                    <a href="/" class="text-white hover:text-opacity-70 transition-all">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M12.9405 0.574952C12.1365 0.116952 11.1015 0.00195233 10.0675 0.00195242C4.54755 0.0009529 0.0605473 4.48795 0.0605477 10.009C0.0605481 14.151 2.59055 17.83 6.38755 19.326C6.27254 18.3707 6.27254 17.4052 6.38755 16.45C6.61655 15.644 7.53655 11.504 7.53655 11.504C7.30048 11.0415 7.18166 10.5281 7.19055 10.009C7.19055 8.62795 7.99555 7.59295 9.03155 7.59295C9.72055 7.59295 10.2975 8.16795 10.2975 8.85895L10.2975 8.97395C10.0685 10.124 9.83855 11.159 9.49155 12.308C9.26255 13.113 9.72055 13.918 10.5265 14.035L10.9875 14.035C12.7135 14.035 14.0915 12.194 14.0915 9.43295C14.2075 7.24695 12.4825 5.40795 10.2965 5.40795L9.95055 5.40795C7.65055 5.29195 5.69555 7.13195 5.58055 9.54795L5.58055 9.77695C5.58055 10.582 5.80955 11.386 6.26955 12.078C6.38455 12.192 6.38455 12.307 6.38455 12.307C6.27465 12.6818 6.19802 13.0656 6.15555 13.454C6.15555 13.683 6.03955 13.683 5.80855 13.57C4.54355 12.763 3.73955 11.27 3.73955 9.65695C3.73955 6.55195 6.03955 3.67695 10.2955 3.67695C13.5165 3.56195 16.2755 5.97695 16.3915 9.19795L16.3915 9.42695C16.3915 12.881 14.2055 15.638 11.2175 15.638C10.2955 15.638 9.48855 15.292 8.91355 14.49C8.91355 14.49 8.45455 16.445 8.33855 16.905C7.99355 17.825 7.64955 18.747 7.07255 19.551C12.3645 21.16 17.8845 18.284 19.4935 12.993C21.1045 7.70695 18.2305 2.18595 12.9405 0.574952Z" fill="currentColor"/>
                        </svg>
                    </a>
                    <a href="/" class="text-white hover:text-opacity-70 transition-all">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21.9388 7.9C21.9388 7.1 21.7398 6.3 21.4388 5.5C20.9388 4.1 19.9388 3 18.5388 2.5C17.7398 2.2 16.9388 2.1 16.1388 2L12.0398 2C10.6398 2 9.23984 2 7.83984 2.1C7.03984 2.1 6.23984 2.3 5.53984 2.6C4.23984 3.1 3.13984 4.1 2.63984 5.4C2.33984 6.2 2.13984 7 2.13984 7.9C2.13984 9 2.03984 9.3 2.03984 12C1.93984 13.4 1.93984 14.7 2.03984 16.1C2.03985 16.901 2.23985 17.701 2.53985 18.5C3.03985 19.8 4.13985 20.9 5.43985 21.4C6.23985 21.7 7.03985 21.799 7.83985 21.9C9.23985 22 10.6398 22 12.0398 22C13.4398 22 14.8398 22 16.1388 21.9C16.9398 21.9 17.7408 21.7 18.5388 21.4C19.1945 21.14 19.79 20.7486 20.2887 20.2499C20.7874 19.7511 21.1788 19.1556 21.4388 18.5C21.7398 17.7 21.8368 16.9 21.9388 16.1C21.9388 15 22.0388 14.701 22.0388 12C22.0388 9.299 21.9388 8.9 21.9388 7.9ZM20.1378 16C20.1378 16.6 20.0378 17.3 19.8388 17.9C19.5378 18.798 18.8388 19.5 17.9388 19.798C17.3388 19.999 16.6378 20.099 16.0388 20.099C14.9388 20.099 14.6388 20.201 12.0388 20.201C10.7388 20.201 9.33884 20.201 8.03885 20.099C7.43885 20.099 6.83884 19.999 6.13885 19.799C5.23884 19.499 4.53885 18.799 4.23885 17.9C3.93885 17.299 3.83885 16.7 3.83885 16C3.83884 14.9 3.73884 14.6 3.73884 12C3.73884 10.7 3.73884 9.3 3.83884 8C3.93884 7.4 4.03884 6.7 4.13884 6.1C4.43884 5.2 5.13884 4.5 6.03884 4.2C6.73884 4 7.33884 3.9 8.03884 3.9C9.13884 3.9 9.43884 3.8 12.0388 3.8C13.3388 3.8 14.7398 3.8 16.0388 3.9C16.6388 3.9 17.3398 4 17.9388 4.2C18.8368 4.5 19.5388 5.2 19.8368 6.1C20.0378 6.7 20.1378 7.4 20.1378 8C20.1378 9.1 20.2398 9.4 20.2398 12C20.2388 14.6 20.1378 15 20.1378 16Z" fill="currentColor"/>
                            <path d="M12.1371 6.9C9.23711 6.9 7.03711 9.2 7.03711 12C7.03711 14.8 9.33711 17.1 12.1371 17.1C14.9371 17.1 17.2381 14.8 17.2381 12C17.2381 9.2 14.9371 6.9 12.1371 6.9ZM12.1371 15.3C10.3371 15.3 8.83711 13.8 8.83711 12C8.83711 10.2 10.3371 8.7 12.1371 8.7C13.9371 8.7 15.3371 10.2 15.4371 12C15.4371 13.8 13.9371 15.3 12.1371 15.3ZM17.4371 5.5C16.7381 5.5 16.2381 6 16.2381 6.7C16.2381 7.4 16.7381 7.9 17.4371 7.9C18.1361 7.9 18.6361 7.4 18.6361 6.7C18.6361 6 18.1361 5.5 17.4371 5.5Z" fill="currentColor"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name:'Footer',
    metaInfo() {
        return {
        }
    },
    data() {
        return {
            showDropdown : false,
        }
    },
    computed:{
        ...mapGetters(['showHeaderSearch','expandSearch','noFooter','user', 'isAuthenticated']),
        renderYear(){
            const date = new Date()
            return date.getFullYear()
        },
        showFooter() {
            return !this.noFooter
        },
        isLoggedIn(){
            return this.isAuthenticated
        },
        isGuide(){
            return this.user.guide_entry > 0
        }
    },
    methods: {
       
    },
    components: {

    },
    
}

</script>