import axios from 'axios'

export default {
    getProfile(id) {
        return axios.get(`/api/v1/account/${id}/profile `)
    },
    putProfile(payload) {
        // axios.defaults.headers.common['Content-Type'] ='application/json'
        return axios.put(`/api/profile`, payload)
    },
}