<template>
    <Transition name="mobile-nav">
      <div class="fixed w-full left-0 top-[calc(100dvh_-_84px)] p-5 bg-32838C bg-opacity-90 flex items-center z-20 backdrop-blur-md shadow-mobile-nav" v-show="showMobileNav">
        <div class="flex items-center w-full gap-3">
            <router-link v-if="!isLoggedIn" to="/become-a-guide" class="bg-white  text-32838C hover:bg-opacity-90 hover:text-32838C py-2.5 px-23px inline-flex items-center rounded-30px group transition-all ease-in-out text-base font-semibold duration-300">Become a Local Guide
                <svg class="fill-32838C group-hover:fill-32838C ml-2.5 w-18px h-18px transition-all ease-in-out duration-300" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M9 0C11.3911 0.00615271 13.682 0.961121 15.3695 2.65515C17.057 4.34918 18.0031 6.64374 18 9.03484C17.992 11.3702 17.0765 13.611 15.447 15.2839C13.8174 16.9567 11.6014 17.9307 9.26709 18H8.97677C6.64136 17.9951 4.3993 17.0826 2.72424 15.4552C1.04919 13.8278 0.0723175 11.613 0 9.27871V8.98839C0.00307734 6.60345 0.952647 4.31725 2.64014 2.63193C4.32763 0.946615 6.61506 -1.98538e-06 9 0ZM11.2645 9.87097H6.73548C6.90967 13.2387 8.09419 16.0723 8.91871 16.2581H9C9.78967 16.2581 10.9974 13.5058 11.2413 10.1845L11.2645 9.87097ZM16.2116 9.87097H13.0064C12.9019 12.1471 12.391 14.2606 11.6361 15.7703C12.8136 15.3099 13.8507 14.5504 14.6449 13.5668C15.4392 12.5831 15.9632 11.4093 16.1652 10.1613L16.2116 9.87097ZM4.98193 9.87097H1.8C1.95795 11.1761 2.46688 12.414 3.2726 13.4529C4.07831 14.4917 5.15066 15.2926 6.37548 15.7703C5.5776 14.0552 5.12011 12.2016 5.02838 10.3123L4.99355 9.87097H4.98193ZM6.37548 2.22968L6.23613 2.28774C5.04309 2.77909 4.00232 3.57911 3.22068 4.60565C2.43903 5.6322 1.94468 6.84828 1.78839 8.12903H4.98193C5.08645 6.00387 5.53935 4.01806 6.2129 2.54323L6.37548 2.22968ZM8.98838 1.74194C8.17548 1.74194 6.92129 4.65677 6.73548 8.12903H11.2645C11.0903 4.76129 9.89419 1.92774 9.06967 1.74194H8.98838ZM11.6361 2.24129L11.671 2.29935C12.5019 4.13357 12.9561 6.11599 13.0064 8.12903H16.2C16.0387 6.82805 15.5297 5.5946 14.7265 4.55848C13.9234 3.52236 12.8558 2.72189 11.6361 2.24129Z"/>
                </svg>
            </router-link>
            <button @click="showDropdown = !showDropdown" v-if="showMainNav"
                class="bg-32838C border-none pr-2.5 w-[80px] pl-4 h-44px rounded-full justify-center inline-flex items-center gap-3 shrink-0 ml-auto shadow-button" :class="[showDropdown ? 'bg-opacity-80': 'bg-32838C ']">
                <div class="w-[14px] h-[14px]">
                    <Transition>
                        <template v-if="!showDropdown">
                            <div class="flex gap-1">
                                <img src="/icons/hamburger-icon.svg"/>
                                <img src="/icons/hamburger-icon.svg"/>
                                <img src="/icons/hamburger-icon.svg"/>
                            </div>
                        </template>
                    </Transition>
                    <Transition>
                        <template v-if="showDropdown">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M12.0332 1.96698L1.96729 12.0329" stroke="#ffffff" stroke-width="2" stroke-linecap="round"/>
                            <path d="M12.0332 12.033L1.96729 1.9671" stroke="#ffffff" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                        </template>
                    </Transition>
                </div>
                <svg v-if="!isLoggedIn" class="w-[26px] transform translate-x-1 -translate-y-[2px]" xmlns="http://www.w3.org/2000/svg" width="26" height="23" viewBox="0 0 26 23" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.65678 22.0391C4.50533 20.7128 5.67639 19.5955 7.07416 18.7815C8.55886 17.9167 10.2509 17.4223 11.9922 17.3443C12.1475 17.3373 12.3027 17.3337 12.4577 17.3334C12.6127 17.3337 12.7679 17.3373 12.9231 17.3443C14.6645 17.4223 16.3565 17.9167 17.8412 18.7815C19.3259 19.6461 20.5548 20.8529 21.4131 22.2888C21.4952 22.4263 21.5737 22.5654 21.6485 22.706C24.3186 20.3251 26 16.8589 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 16.5125 1.39304 19.6996 3.65678 22.0391ZM12.9993 14.0833C15.3926 14.0833 17.3327 12.1432 17.3327 9.74996C17.3327 7.35673 15.3926 5.41663 12.9993 5.41663C10.6061 5.41663 8.66602 7.35673 8.66602 9.74996C8.66602 12.1432 10.6061 14.0833 12.9993 14.0833Z" fill="#E5F2F4"/>
                </svg>
                <div v-else class="w-[26px] h-[26px] bg-white border-2 border-E5F2F4 flex items-center justify-center rounded-full  transform translate-x-1 overflow-hidden">
                    <svg v-if="user.profile_image === null" xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16" fill="none">
                        <path d="M5.37065 10.8578C2.40872 10.8578 0 8.42228 0 5.42932C0 2.43635 2.40956 0 5.37065 0C8.33173 0 10.7421 2.4355 10.7421 5.42932C10.7421 8.42313 8.33257 10.8578 5.37065 10.8578ZM5.37065 2.41681C3.72784 2.41681 2.39106 3.76797 2.39106 5.42932C2.39106 7.09066 3.72784 8.44098 5.37065 8.44098C7.01345 8.44098 8.35107 7.08981 8.35107 5.42932C8.35107 3.76882 7.01429 2.41681 5.37065 2.41681Z" fill="#32838C"/>
                        <path d="M5.62631 15.5512C4.16678 15.5512 2.73668 15.1509 1.49155 14.3938C0.925731 14.0496 0.743292 13.3069 1.08295 12.735C1.42345 12.1631 2.15826 11.9787 2.72407 12.322C3.5976 12.8531 4.60061 13.1335 5.62631 13.1335C6.65201 13.1335 7.64324 12.8565 8.51257 12.3313C9.07923 11.9889 9.81319 12.1767 10.152 12.7494C10.4908 13.3222 10.305 14.0641 9.73837 14.4065C8.49912 15.1543 7.07659 15.5503 5.62631 15.5503V15.5512Z" fill="#32838C"/>
                    </svg>
                    <img v-else :src="user.profile_image" class="object-cover"/>
                </div>
            </button>
        </div>   
    </div>
    </Transition>
    <Transition name="mobile-menu">
        <div v-if="showDropdown" class="fixed z-50 top-0 right-0 bg-white bg-opacity-80 backdrop-blur-md p-1.5 w-full left-0 space-y-0.5 h-[calc(100dvh_-_84px)] flex justify-items-end">
            <div class="w-full mt-auto">
                <router-link  v-if="!isLoggedIn" to="/login" class="w-full flex items-center px-3 py-2 hover:bg-32838C group/actions transition-all duration-300 ease-in-out rounded-10px">
                    <div class="flex flex-col px-2">
                        <span class="text-17px text-32838C group-hover/actions:text-white font-bold">Login</span>
                    </div>
                    <div class="flex ml-auto opacity-0 transform scale-0 group-hover/actions:opacity-100 group-hover/actions:scale-100 transition-all duration-300 ease-in-out">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2929 7.29289C10.6534 6.93241 11.2206 6.90468 11.6129 7.2097L11.7071 7.29289L17.7071 13.2929C18.0676 13.6534 18.0953 14.2206 17.7903 14.6129L17.7071 14.7071L11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071C9.93241 20.3466 9.90468 19.7794 10.2097 19.3871L10.2929 19.2929L15.585 14L10.2929 8.70711C9.93241 8.34662 9.90468 7.77939 10.2097 7.3871L10.2929 7.29289Z" fill="#ffffff"/>
                        <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ffffff"/>
                        </svg>
                    </div>
                </router-link>
                <router-link v-if="!isLoggedIn" to="/login" class="w-full flex items-center px-3 py-2 hover:bg-32838C group/actions transition-all duration-300 ease-in-out rounded-10px">
                    <div class="flex flex-col px-2">
                        <span class="text-17px text-32838C group-hover/actions:text-white font-bold">Register</span>
                    </div>
                    <div class="flex ml-auto opacity-0 transform scale-0 group-hover/actions:opacity-100 group-hover/actions:scale-100 transition-all duration-300 ease-in-out">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2929 7.29289C10.6534 6.93241 11.2206 6.90468 11.6129 7.2097L11.7071 7.29289L17.7071 13.2929C18.0676 13.6534 18.0953 14.2206 17.7903 14.6129L17.7071 14.7071L11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071C9.93241 20.3466 9.90468 19.7794 10.2097 19.3871L10.2929 19.2929L15.585 14L10.2929 8.70711C9.93241 8.34662 9.90468 7.77939 10.2097 7.3871L10.2929 7.29289Z" fill="#ffffff"/>
                        <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ffffff"/>
                        </svg>
                    </div>
                </router-link>
                <router-link v-if="isLoggedIn" to="/my-travels" class="w-full flex items-center px-3 py-2 hover:bg-32838C group/actions transition-all duration-300 ease-in-out rounded-10px">
                    <div class="flex flex-col px-2">
                        <span class="text-17px text-32838C group-hover/actions:text-white font-bold">My Travels</span>
                    </div>
                    <div class="flex ml-auto opacity-0 transform scale-0 group-hover/actions:opacity-100 group-hover/actions:scale-100 transition-all duration-300 ease-in-out">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2929 7.29289C10.6534 6.93241 11.2206 6.90468 11.6129 7.2097L11.7071 7.29289L17.7071 13.2929C18.0676 13.6534 18.0953 14.2206 17.7903 14.6129L17.7071 14.7071L11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071C9.93241 20.3466 9.90468 19.7794 10.2097 19.3871L10.2929 19.2929L15.585 14L10.2929 8.70711C9.93241 8.34662 9.90468 7.77939 10.2097 7.3871L10.2929 7.29289Z" fill="#ffffff"/>
                        <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ffffff"/>
                        </svg>
                    </div>
                </router-link>
                <div class="w-full rounded-10px bg-596869 bg-opacity-5">
                    <router-link v-if="isLoggedIn" to="/my-profile" class="w-full flex items-center px-3 py-2 hover:bg-32838C group/actions transition-all duration-300 ease-in-out rounded-10px">
                        <div class="flex flex-col px-2">
                            <span class="text-17px text-32838C group-hover/actions:text-white font-bold">Profile</span>
                        </div>
                        <div class="flex ml-auto opacity-0 transform scale-0 group-hover/actions:opacity-100 group-hover/actions:scale-100 transition-all duration-300 ease-in-out">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2929 7.29289C10.6534 6.93241 11.2206 6.90468 11.6129 7.2097L11.7071 7.29289L17.7071 13.2929C18.0676 13.6534 18.0953 14.2206 17.7903 14.6129L17.7071 14.7071L11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071C9.93241 20.3466 9.90468 19.7794 10.2097 19.3871L10.2929 19.2929L15.585 14L10.2929 8.70711C9.93241 8.34662 9.90468 7.77939 10.2097 7.3871L10.2929 7.29289Z" fill="#ffffff"/>
                            <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ffffff"/>
                            </svg>
                        </div>
                    </router-link>
                    <router-link v-if="isLoggedIn" to="/orders" class="w-full flex items-center px-3 py-2 hover:bg-32838C group/actions transition-all duration-300 ease-in-out rounded-10px">
                        <div class="flex flex-col px-2">
                            <span class="text-17px text-32838C group-hover/actions:text-white font-bold">Orders</span>
                        </div>
                        <div class="flex ml-auto opacity-0 transform scale-0 group-hover/actions:opacity-100 group-hover/actions:scale-100 transition-all duration-300 ease-in-out">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2929 7.29289C10.6534 6.93241 11.2206 6.90468 11.6129 7.2097L11.7071 7.29289L17.7071 13.2929C18.0676 13.6534 18.0953 14.2206 17.7903 14.6129L17.7071 14.7071L11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071C9.93241 20.3466 9.90468 19.7794 10.2097 19.3871L10.2929 19.2929L15.585 14L10.2929 8.70711C9.93241 8.34662 9.90468 7.77939 10.2097 7.3871L10.2929 7.29289Z" fill="#ffffff"/>
                            <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ffffff"/>
                            </svg>
                        </div>
                    </router-link>
                    <router-link v-if="isLoggedIn" to="/my-expert-areas" class="w-full flex items-center px-3 py-2 hover:bg-32838C group/actions transition-all duration-300 ease-in-out rounded-10px">
                        <div class="flex flex-col px-2">
                            <span class="text-17px text-32838C group-hover/actions:text-white font-bold">Expert Areas</span>
                        </div>
                        <div class="flex ml-auto opacity-0 transform scale-0 group-hover/actions:opacity-100 group-hover/actions:scale-100 transition-all duration-300 ease-in-out">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2929 7.29289C10.6534 6.93241 11.2206 6.90468 11.6129 7.2097L11.7071 7.29289L17.7071 13.2929C18.0676 13.6534 18.0953 14.2206 17.7903 14.6129L17.7071 14.7071L11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071C9.93241 20.3466 9.90468 19.7794 10.2097 19.3871L10.2929 19.2929L15.585 14L10.2929 8.70711C9.93241 8.34662 9.90468 7.77939 10.2097 7.3871L10.2929 7.29289Z" fill="#ffffff"/>
                            <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ffffff"/>
                            </svg>
                        </div>
                    </router-link>
                    <router-link v-if="isLoggedIn" to="/my-experiences" class="w-full flex items-center px-3 py-2 hover:bg-32838C  group/actions transition-all duration-300 ease-in-out rounded-10px">
                        <div class="flex flex-col px-2">
                            <span class="text-17px text-32838C group-hover/actions:text-white font-bold">Experiences</span>
                        </div>
                        <div class="flex ml-auto opacity-0 transform scale-0 group-hover/actions:opacity-100 group-hover/actions:scale-100 transition-all duration-300 ease-in-out">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2929 7.29289C10.6534 6.93241 11.2206 6.90468 11.6129 7.2097L11.7071 7.29289L17.7071 13.2929C18.0676 13.6534 18.0953 14.2206 17.7903 14.6129L17.7071 14.7071L11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071C9.93241 20.3466 9.90468 19.7794 10.2097 19.3871L10.2929 19.2929L15.585 14L10.2929 8.70711C9.93241 8.34662 9.90468 7.77939 10.2097 7.3871L10.2929 7.29289Z" fill="#ffffff"/>
                            <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ffffff"/>
                            </svg>
                        </div>
                    </router-link>
                    <router-link v-if="isLoggedIn" to="/my-earnings" class="w-full flex items-center px-3 py-2 hover:bg-32838C  group/actions transition-all duration-300 ease-in-out rounded-10px">
                        <div class="flex flex-col px-2">
                            <span class="text-17px text-32838C group-hover/actions:text-white font-bold">Earnings</span>
                        </div>
                        <div class="flex ml-auto opacity-0 transform scale-0 group-hover/actions:opacity-100 group-hover/actions:scale-100 transition-all duration-300 ease-in-out">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2929 7.29289C10.6534 6.93241 11.2206 6.90468 11.6129 7.2097L11.7071 7.29289L17.7071 13.2929C18.0676 13.6534 18.0953 14.2206 17.7903 14.6129L17.7071 14.7071L11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071C9.93241 20.3466 9.90468 19.7794 10.2097 19.3871L10.2929 19.2929L15.585 14L10.2929 8.70711C9.93241 8.34662 9.90468 7.77939 10.2097 7.3871L10.2929 7.29289Z" fill="#ffffff"/>
                            <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ffffff"/>
                            </svg>
                        </div>
                    </router-link>
                    <router-link v-if="isLoggedIn" to="/training" class="w-full flex items-center px-3 py-2 hover:bg-32838C group/actions transition-all duration-300 ease-in-out rounded-10px">
                        <div class="flex flex-col px-2">
                            <span class="text-17px text-32838C group-hover/actions:text-white font-bold">Training</span>
                        </div>
                        <div class="flex ml-auto opacity-0 transform scale-0 group-hover/actions:opacity-100 group-hover/actions:scale-100 transition-all duration-300 ease-in-out">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2929 7.29289C10.6534 6.93241 11.2206 6.90468 11.6129 7.2097L11.7071 7.29289L17.7071 13.2929C18.0676 13.6534 18.0953 14.2206 17.7903 14.6129L17.7071 14.7071L11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071C9.93241 20.3466 9.90468 19.7794 10.2097 19.3871L10.2929 19.2929L15.585 14L10.2929 8.70711C9.93241 8.34662 9.90468 7.77939 10.2097 7.3871L10.2929 7.29289Z" fill="#ffffff"/>
                            <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ffffff"/>
                            </svg>
                        </div>
                    </router-link>
                    <router-link v-if="isAdmin" to="/admin" class="w-full flex items-center px-3 py-2 hover:bg-32838C group/actions transition-all duration-300 ease-in-out rounded-10px">
                        <div class="flex flex-col px-2">
                            <span class="text-17px text-32838C group-hover/actions:text-white font-bold">Admin</span>
                        </div>
                        <div class="flex ml-auto opacity-0 transform scale-0 group-hover/actions:opacity-100 group-hover/actions:scale-100 transition-all duration-300 ease-in-out">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2929 7.29289C10.6534 6.93241 11.2206 6.90468 11.6129 7.2097L11.7071 7.29289L17.7071 13.2929C18.0676 13.6534 18.0953 14.2206 17.7903 14.6129L17.7071 14.7071L11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071C9.93241 20.3466 9.90468 19.7794 10.2097 19.3871L10.2929 19.2929L15.585 14L10.2929 8.70711C9.93241 8.34662 9.90468 7.77939 10.2097 7.3871L10.2929 7.29289Z" fill="#ffffff"/>
                            <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ffffff"/>
                            </svg>
                        </div>
                    </router-link>
                    <router-link v-if="isIosApplication" to="/app/event/settings" class="w-full flex items-center px-3 py-2 hover:bg-32838C group/actions transition-all duration-300 ease-in-out rounded-10px">
                        <div class="flex flex-col px-2">
                            <span class="text-17px text-32838C group-hover/actions:text-white font-bold">Settings</span>
                        </div>
                        <div class="flex ml-auto opacity-0 transform scale-0 group-hover/actions:opacity-100 group-hover/actions:scale-100 transition-all duration-300 ease-in-out">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2929 7.29289C10.6534 6.93241 11.2206 6.90468 11.6129 7.2097L11.7071 7.29289L17.7071 13.2929C18.0676 13.6534 18.0953 14.2206 17.7903 14.6129L17.7071 14.7071L11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071C9.93241 20.3466 9.90468 19.7794 10.2097 19.3871L10.2929 19.2929L15.585 14L10.2929 8.70711C9.93241 8.34662 9.90468 7.77939 10.2097 7.3871L10.2929 7.29289Z" fill="#ffffff"/>
                            <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ffffff"/>
                            </svg>
                        </div>
                    </router-link>
                </div>
                <button v-if="isLoggedIn" type="button" @click="logout" class="w-full flex items-center px-3 py-2 hover:bg-32838C group/actions transition-all duration-300 ease-in-out rounded-10px">
                    <div class="flex flex-col px-2">
                        <span class="text-17px text-32838C group-hover/actions:text-white font-bold">Sign out</span>
                    </div>
                    <div class="flex ml-auto opacity-0 transform scale-0 group-hover/actions:opacity-100 group-hover/actions:scale-100 transition-all duration-300 ease-in-out">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2929 7.29289C10.6534 6.93241 11.2206 6.90468 11.6129 7.2097L11.7071 7.29289L17.7071 13.2929C18.0676 13.6534 18.0953 14.2206 17.7903 14.6129L17.7071 14.7071L11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071C9.93241 20.3466 9.90468 19.7794 10.2097 19.3871L10.2929 19.2929L15.585 14L10.2929 8.70711C9.93241 8.34662 9.90468 7.77939 10.2097 7.3871L10.2929 7.29289Z" fill="#ffffff"/>
                        <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ffffff"/>
                        </svg>
                    </div>
                </button>
            </div>
        </div>
    </Transition>
    
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name:'Mobile Navigation',
    beforeMount(){
        window.addEventListener('scroll', this.handleScroll)
    },
    metaInfo() {
        return {
        }
    },
    data() {
        return {
            showDropdown : false,
            isScrolling:null,
            showMobileNav:true,
        }
    },
    watch:{
        $route (to, from){
            this.showDropdown = false
        },
    },
    computed:{
        ...mapGetters(['showHeaderSearch','expandSearch','noFooter','user', 'isAuthenticated','noMainNav','isIosApp']),
        renderYear(){
            const date = new Date()
            return date.getFullYear()
        },
        showFooter() {
            return !this.noFooter
        },
        isLoggedIn(){
            return this.isAuthenticated
        },
        showMainNav() {
            return !this.noMainNav
        },
        showBack(){
            return window.history.length > 1
        },
        isIosApplication(){
            return this.isIosApp
        },
    },
    methods: {
        resetDropdown() {
            this.showDropdown = false
        },
        logout (){
            this.$store.dispatch('userLogout')
        },
        enterFrame() {
            console.log('enterFrame')
        },
        goToDashboard() {
            if (this.noMainNav) {
                return
            }

            if (this.isLoggedIn){
                this.$router.push({ name: 'MyTravels'})
            } else {
                this.$router.push({ name: 'publicDashboard'})
            }

        },
        handleScroll(){
            if(this.showDropdown){
                return
            }
            //this.showMobileNav = false
            clearTimeout(this.isScrolling)
           
            this.isScrolling = setTimeout(()=> {
                console.log('stop scroll?')
                //this.showMobileNav = true
            }, 600)
            
        },
    },
    components: {

    },
    beforeUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    },
}

</script>
<style>
.mobile-menu-enter-from {
  opacity: 0;
  transform: translateX(400px);
}
.mobile-menu-enter-active {
  transition: all 0.3s ease-out;
}
.mobile-menu-leave-to {
  opacity: 0;
  transform: translateX(400px);
}
.mobile-menu-leave-active {
  transition: all 0.3s ease-in;
}
.mobile-nav-enter-from {
  opacity: 0;
  transform: translateY(400px);
}
.mobile-nav-enter-active {
  transition: all 0.3s ease-out;
}
.mobile-nav-leave-to {
  opacity: 0;
  transform: translateY(400px);
}
.mobile-nav-leave-active {
  transition: all 0.3s ease-in;
}
</style>