// import Vue from 'vue'
import * as types from '@/store/mutation-types.js'
import api from '@/services/api/orders'
import { buildSuccess, handleError } from '@/utils/utils.js'

const actions = {
    createCheckoutCall({commit}, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true)
            api.createCheckoutCall(payload)
                .then((response) => {
                    commit(types.SHOW_LOADING, false)
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    createCheckoutVirtualGuide({commit}, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true)
            api.createCheckoutVirtualGuide(payload)
                .then((response) => {
                    commit(types.SHOW_LOADING, false)
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    createCheckoutLocalRecommendations({commit}, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true)
            api.createCheckoutLocalRecommendations(payload)
                .then((response) => {
                    commit(types.SHOW_LOADING, false)
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    }
}

const state = {

}

const mutations = {

}

const getters = {

}


export default {
    state,
    getters,
    actions,
    mutations,
}