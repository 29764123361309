import axios from 'axios'

export default {
    createExperience(payload) {
        return axios.post(`/api/experience`, payload)
    },
    updateExperience(payload) {
        return axios.put(`/api/experience/${payload.id}`, payload)
    },
    getExperience(id) {
        return axios.get(`/api/experience/${id}`)
    },
    getExperienceData(id) {
        return axios.get(`/api/guide/experience/${id}`);
    },
    submitExperienceForReview(id){
        return axios.post(`/api/experience/${id}/review`)
    },
    getMyAccountExperiences()
    {
        return axios.get(`/api/guide/experiences`)
    },
    getExperienceScheduleDays(payload)
    {
        let params = {}

        if (payload.preview) {
            params.mode = 'preview'
        }

        if (payload.start_date) {
            params.start_date = payload.start_date
        }

        if (payload.end_date) {
            params.end_date = payload.end_date
        }

        return axios.get(`/api/experience/${payload.experience_id}/schedule`, {params: params})
    }
}