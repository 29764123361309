<template>
    <div class="w-full fixed left-0 right-0 z-50 bg-effdff" :class="{'search-expanded':expandSearchVar}" ref="header" v-if="showHeader">
        <div class="max-w-1141px mx-auto w-full relative z-50">
            <div class="flex items-center py-3 px-4 xl:px-0">
                <div class="logo relative overflow-hidden cursor-pointer" @click="goToDashboard">
                    <router-link to="/">
                        <svg class="w-50px" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" width="70.085854" height="99.982513" viewBox="0 0 70.085854 99.982513" fill="none" version="1.1" id="svg3" sodipodi:docname="Group 23.svg" inkscape:version="1.3 (0e150ed, 2023-07-21)">
                        <path d="M 29.13391,74.522624 C 13.06649,74.522624 0,61.310694 0,45.074955 0,28.839157 13.071058,15.622822 29.13391,15.622822 c 16.062852,0 29.138425,13.21171 29.138425,29.452133 0,16.240469 -13.071006,29.447669 -29.138425,29.447669 z m 0,-45.789474 c -8.911644,0 -16.163186,7.329619 -16.163186,16.341805 0,9.012347 7.251542,16.337069 16.163186,16.337069 8.911643,0 16.167753,-7.329447 16.167753,-16.337069 0,-9.007569 -7.251542,-16.341805 -16.167753,-16.341805 z" fill="#32838c" id="path1" style="stroke-width:0.525033"></path>
                        <path d="m 70.085571,7.6892622 c 0.0362,4.2732938 -3.406941,7.6799688 -7.72166,7.6477318 C 58.141076,15.309324 54.875373,11.990225 54.852797,7.7030702 54.834417,3.4021272 58.246083,-0.01836579 62.532976,7.4207615e-5 66.614577,0.01851421 70.053541,3.5219832 70.085571,7.6892622 Z" fill="#32838c" id="path2" style="stroke-width:0.525033"></path>
                        <path d="m 30.520731,99.982514 c -7.917441,0 -15.67522,-2.17101 -22.429661,-6.27834 -3.069357,-1.86702 -4.059029,-5.89612 -2.216478,-8.99854 1.847065,-3.10241 5.833166,-4.1026 8.902508,-2.24031 4.738578,2.88085 10.179544,4.4024 15.743631,4.4024 5.564087,0 10.941158,-1.50265 15.656949,-4.352 3.073963,-1.85756 7.055391,-0.839 8.89353,2.26815 1.837615,3.10713 0.830077,7.13151 -2.24399,8.98908 -6.722519,4.05693 -14.439241,6.20484 -22.306489,6.20484 z" fill="#32838c" id="path3" style="stroke-width:0.525033"></path>
                        </svg>
                    </router-link>
                </div>
                <div class="search-container flex ml-auto">
                    <MobileSearch :visible="showSearch" :expanded="false" :close="true"/>
                    <div v-if="!showSearch && appTitle !== 'YouGo'"><h3 class="text-4xl text-center text-medium text-32838C">{{ appTitle }}</h3></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import LogoJSON from './animations/YouGo_Logo.json'
import { mapGetters, mapActions } from 'vuex'
import { computed, watch } from 'vue'

export default {
    metaInfo() {
        return {
        }
    },
    data() {
        return {
            showDropdown : false,
            LogoJSON,
        }
    },
    mounted() {

    },
    computed:{
        ...mapGetters(['showHeaderSearch','expandSearch','noHeader','isAuthenticated', 'isAdmin', 'noMainNav', 'user','appTitle',]),
        showSearch() {
            return this.showHeaderSearch
        },
        expandSearchVar() {
            return this.expandSearch
        },
        showHeader() {
            return !this.noHeader
        },
        isLoggedIn(){
            return this.isAuthenticated
        },
        showMainNav() {
            return !this.noMainNav
        }
    },
    methods: {
        resetDropdown() {
            this.showDropdown = false
        },
        enterFrame() {
            console.log('enterFrame')
        },
        goToDashboard() {
            if (this.noMainNav) {
                return
            }

            if (this.isLoggedIn){
                this.$router.push({ name: 'MyTravels'})
            } else {
                this.$router.push({ name: 'publicDashboard'})
            }

        },
    },
    components: {
        Vue3Lottie,
    },
    watch: {
        '$route' () {
            this.resetDropdown()
            if (this.$route.path === "/") {
                //this.showSearch = false
            } else{
                //this.showSearch = true
            }
        },
        // 'user' (newUserInfo, oldUserInfo) {
        //     if (newUserInfo.force_quiz && !this.$route.fullPath.startsWith('/start-quiz')) {
        //         this.$router.push({path: '/start-quiz'})
        //     }
        // }
    }
}

</script>