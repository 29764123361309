// import Vue from 'vue'
import * as types from '@/store/mutation-types.js'
import api from '@/services/api/search'
import { buildSuccess, handleError } from '@/utils/utils.js'

const getters = {
    getSearch:  (state) => state.search,

}

const actions = {
    getLocations({commit}, payload){
        return new Promise((resolve, reject) => {
            api.getLocations(payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    getLocation({commit}, id){
        return new Promise((resolve, reject) => {
            api.getLocation(id)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    getLocalGuides({commit}, payload){
        return new Promise((resolve, reject) => {
            api.getLocalGuides(payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    getLocalRecommedations({commit}, payload){
        return new Promise((resolve, reject) => {
            api.getLocalRecommedations(payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    getLocalExperiences({commit}, payload){
        return new Promise((resolve, reject) => {
            api.getLocalExperiences(payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    updateSearch ({ commit }, payload) {
        commit('setSearch', payload)
    }
}

const state = {
    search:{
        askGuide:false,
        localInfo:false,
        experiences:false,
        location:{
            name:'',
        },
        dates:null,
        locations:[],
        showLocations:false,
    },
}

const mutations = { 
    setSearch(state, value) {
        state.search = value
    },
}


export default {
    state,
    getters,
    actions,
    mutations,
}