// import Vue from 'vue'
import * as types from '@/store/mutation-types.js'
import api from '@/services/api/guides'
import { buildSuccess, handleError } from '@/utils/utils.js'

const actions = {
    getGuideQuizData({commit}, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true)
            api.getGuideQuizData(payload)
                .then((response) => {
                    commit(types.SHOW_LOADING, false)
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    submitGuideQuiz({commit}, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true)
            api.submitGuideQuiz(payload)
                .then((response) => {
                    commit(types.SHOW_LOADING, false)
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    submitGuideQuizForReview({commit}, areaId) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true)
            api.submitGuideQuizForReview(areaId)
                .then((response) => {
                    commit(types.SHOW_LOADING, false)
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    getMyExpertAreas({commit}) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true)
            api.getGuideMyExpertAreas()
                .then((response) => {
                    commit(types.SHOW_LOADING, false)
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    getMyExpertArea({commit}, areaId) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true)
            api.getMyExpertArea(areaId)
                .then((response) => {
                    commit(types.SHOW_LOADING, false)
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    deleteGuideExpertArea({commit}, areaId) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true)
            api.deleteGuideExpertArea(areaId)
                .then((response) => {
                    commit(types.SHOW_LOADING, false)
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    }
}

const state = {

}

const mutations = {

}

const getters = {

}


export default {
    state,
    getters,
    actions,
    mutations,
}