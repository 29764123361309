import axios from 'axios'

export default {
    accountCheck(payload) {
        return axios.post(`/api/v1/account/check`, payload)
    },
    accountLogin(payload) {
        return axios.post(`/api/v2/account/login`, payload)
    },
    accountCreate(payload) {
        return axios.post(`/api/v2/account/register`, payload)
    },
    refreshToken() {
        return axios.post(`/api/v2/account/auth/refresh-tokens`)
    },
    fetchUser() {
        return axios.get(`/api/v1/account`)
    }
}