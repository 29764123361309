import Vue from 'vue'
import {createStore} from 'vuex'
import modules from '@/store/modules'

export const store = createStore({
  state: {
    refresh_token: "",
    access_token: "",
    showHeaderSearch:true,
    expandSearch:false,
  },
  modules: {
    ...modules
  },
})