import axios from 'axios'

export default {
    createCheckoutCall(payload) {
        axios.defaults.headers.common['Content-Type'] ='application/json'
        return axios.post(`/api/v1/checkout/call`, payload)
    },
    createCheckoutVirtualGuide(payload) {
        axios.defaults.headers.common['Content-Type'] ='application/json'
        return axios.post(`/api/v1/checkout/virtual-guide`, payload)
    },
    createCheckoutLocalRecommendations(payload) {
        axios.defaults.headers.common['Content-Type'] ='application/json'
        return axios.post(`/api/v1/checkout/local-recommendations`, payload)
    },
}