// import Vue from 'vue'
import * as types from '@/store/mutation-types.js'
import api from '@/services/api/experience'
import { buildSuccess, handleError } from '@/utils/utils.js'

const getters = {


}

const actions = {
    createExperience({commit}, payload){
        return new Promise((resolve, reject) => {
            api.createExperience(payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    updateExperience({commit}, payload){
        return new Promise((resolve, reject) => {
            api.updateExperience(payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    getExperience({commit}, payload){
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true)
            api.getExperience(payload)
                .then((response) => {
                    commit(types.SHOW_LOADING, false)
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    getExperienceData({commit}, payload){
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true)
            api.getExperienceData(payload)
                .then((response) => {
                    commit(types.SHOW_LOADING, false)
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    getMyAccountExperiences({commit}, payload){
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true)
            api.getMyAccountExperiences(payload)
                .then((response) => {
                    commit(types.SHOW_LOADING, false)
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    getExperienceScheduleDays({commit}, payload){
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true)
            api.getExperienceScheduleDays(payload)
                .then((response) => {
                    commit(types.SHOW_LOADING, false)
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    submitForReview({commit}, payload){
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true)
            api.submitForReview(payload)
                .then((response) => {
                    commit(types.SHOW_LOADING, false)
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
}

const state = {

}

const mutations = {

}


export default {
    state,
    getters,
    actions,
    mutations,
}