/** @type {import('tailwindcss').Config} */
const plugin = require('tailwindcss/plugin')

module.exports = {
    // purge: ['./resources/index.html', './resources/**/*.{vue,js,ts,jsx,tsx}'],
    content: [
        './resources/**/*.{js,vue}',
        './resources/*.{js,vue}',
        './resources/index.html'
    ],
    theme: {
        extend: {
            fontSize:{
                '11px': '11px',
                '15px': '15px',
                '17px': '17px',
                '28px': '28px',
                '32px': '32px',
                '34px': '34px',
                '40px':'40px',
            },
            lineHeight:{
                '22px': '22px',
                '23': '1.463',
                '38px': '38px',
                '41': '2.6rem',
                '48px':'48px',
            },
            minWidth: {
                '340px':'340px',
            },
            minHeight: {
                '46px':'46px',
            },
            maxWidth: {
                '70px':'70px',
                '160px':'160px',
                '200px':'200px',
                '278px':'278px',
                '332px':'332px',
                '366px':'366px',
                '380px':'380px',
                '440px':'440px',
                '480px':'480px',
                '579px': '579px',
                '591px': '591px',
                '700px': '700px',
                '1141px': '1141px',
            },
            maxHeight: {
                '70px':'70px',
                '200px':'200px',
                '400px':'400px',
            },
            colors: {
                'FAFEFE':'#FAFEFE',
                'FAFAFA':'#FAFAFA',
                '32838C':'#32838C',
                '94CAD0':'#94CAD0',
                '070707':'#070707',
                '343724':'#343724',
                'DDFAFD':'#DDFAFD',
                '53BBC7':'#53BBC7',
                '4A4A4A':'#4A4A4A',
                '1B3B3F':'#1B3B3F',
                'CFE94C':'#CFE94C',
                'EFFDFF':'#EFFDFF',
                '6C7833':'#6C7833',
                '164B51':'#164B51',
                '81912F':'#81912F',
                'EFFDFFE3':'#EFFDFFE3',
                '32838CAD':'#32838CAD',
                '81912F':'#81912F',
                '6F6F16':'#6F6F16',
                '2B2D26':'#2B2D26',
                '2C6269':'#2C6269',
                'effdff':'#effdff',
                'B1B1B1':'#B1B1B1',
                '161616':'#161616',
                'EEFAFC':'#EEFAFC',
                'CAE43E':'#CAE43E',
                'EEEEEE':'#EEEEEE',
                '596869':'#596869',
                '6BA8AF':'#6BA8AF',
                'ABC03F':'#ABC03F',
                'BFBFBE':'#BFBFBE',
                'E8E8E8':'#E8E8E8',
                '1F1F1F':'#1F1F1F',
                '3B5896':'#3B5896',
                '0373F3':'#0373F3',
                '34A853':'#34A853',
                'A3C7CB':'#A3C7CB',
                '70A8AF':'#70A8AF',
                'E5F2F4':'#E5F2F4',
                'e1e5e5':'#e1e5e5',
                'EEFAFC':'#EEFAFC',
                'e4f4f7':'#e4f4f7',
            },
            spacing: {
                '1px': '1px',
                '7px': '7px',
                '18px': '18px',
                '23px': '23px',
                '30px': '30px',
                '34px': '34px',
                '44px': '44px',
                '50px': '50px',
                '90px': '90px',
                '108px': '108px',
                '125px': '125px',
                '140px': '140px',
                '160px': '160px',
                '174px': '174px',
                '179px': '179px',
                '190px': '190px',
                '200px': '200px',
                '207px': '207px',
                '238px': '238px',
                '241px': '241px',
                '332px': '332px',
                '340px': '340px',
                '380px': '380px',
                '366px': '366px',
                '465px': '465px',
                '580px': '580px',
                '640px': '640px',
            },
            boxShadow:{
                'button':'4px 3px 20px 0px rgb(0 0 0 / 20%)',
                'buttons':'0px 15px 35px -2px rgba(0, 0, 0, 0.25)',
                'image':'13px 10px 30px 0px rgba(0, 0, 0, 0.12)',
                'guide-box':'11px 15px 44px rgba(0, 0, 0, 0.25)',
                'mobile-nav':'0px -4px 9px rgba(0, 0, 0, 0.25)',
            },
            dropShadow: {
                'big-box': '11px 15px 44px rgba(0, 0, 0, 0.25)',
            },
            borderRadius:{
                '10px': '10px',
                '20px': '20px',
                '30px': '30px',
                '43px': '43px',
                '49px': '49px',
            },
            borderWidth:{
                '1.5' : '1.5px',
            },
            zIndex:{
                '1000': '1000',
            },
            backdropBlur: {
                'xs': '1px',
            }
        },
    },
    plugins: [
        plugin(function({ addUtilities }) {
        const newUtilities = {
          '.text-balance': {
                'text-wrap':'balance'
          },
        }

        addUtilities(newUtilities, ['responsive', 'hover'])
      })],
}

