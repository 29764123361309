<template>
    <Transition name="search" appear>
    <div class="w-full" v-show="visible" :class="{'expandSize': showForm || expanded, 'h-[300px] sm:h-[200px]':showActions}" ref="searchForm">
        <div v-show="!expended" v-if="layout !== 'pills-layout'" class="mx-auto flex border-2 border-32838C px-5 py-1.5 rounded-49px items-center justify-center mt-5 max-w-380px min-w-340px collapse-header-search-block bg-EFFDFF">
            <div>
                <button :title="initSearchValues.location.name" type="button" class="p-2 bg-transparent border-none outline-none text-1B3B3F text-15px" @click.stop="showForm = true; setHeaderClass();">
                    <span class="line-clamp-1 w-full">{{ whereLabel }}</span>
                </button>
            </div>
            <div class="mx-3 h-30px w-1px bg-32838C"></div>
            <div>
                <button type="button" class="whitespace-nowrap p-2 bg-transparent border-none outline-none text-1B3B3F text-15px" @click.stop="showForm = true; setHeaderClass(); openCalendar();">
                    {{ whenLabel }}
                </button>
            </div>
            <div>
                <button type="button" class="p-2 bg-transparent border-none outline-none" @click.stop="searchActionsFn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9167 6.75793C10.9167 9.05474 9.05474 10.9167 6.75793 10.9167C4.46113 10.9167 2.59921 9.05474 2.59921 6.75793C2.59921 4.46113 4.46113 2.59921 6.75793 2.59921C9.05474 2.59921 10.9167 4.46113 10.9167 6.75793ZM9.83425 12.7767C8.91152 13.2493 7.86587 13.5159 6.75793 13.5159C3.02563 13.5159 0 10.4902 0 6.75793C0 3.02563 3.02563 0 6.75793 0C10.4902 0 13.5159 3.02563 13.5159 6.75793C13.5159 8.44319 12.899 9.98437 11.8787 11.168L14.5031 14.2297C14.9702 14.7747 14.9071 15.5951 14.3621 16.0622C13.8171 16.5293 12.9967 16.4662 12.5296 15.9212L9.83425 12.7767Z" fill="#32838C"/>
                    </svg>
                </button>
            </div>
        </div>
        <div class="w-full expand-header-search-block" :class="bgForm">
            <div class="flex justify-around w-full" v-if="layout === 'default'">
                <button type="button" class="bg-transparent px-0 py-0 text-32838C text-xs transition-all duration-100 ease-in-out" :class="{'font-bold underline underline-offset-2': initSearchValues.askGuide}" @click.stop="initSearchValues.askGuide=true; initSearchValues.localInfo = false; initSearchValues.experiences = false;  goToSearchResults()">Local Guide</button>
                <button type="button" class="bg-transparent px-0 py-0 text-32838C text-xs transition-all duration-100 ease-in-out" :class="{'font-bold underline underline-offset-2': initSearchValues.localInfo}" @click.stop="initSearchValues.localInfo = true; initSearchValues.askGuide = false; initSearchValues.experiences = false;  goToSearchResults()">Local Recommendations</button>
                <button type="button" class="bg-transparent px-0 py-0 text-32838C text-xs transition-all duration-100 ease-in-out" :class="{'font-bold underline underline-offset-2': initSearchValues.experiences}" @click.stop="initSearchValues.experiences = true; initSearchValues.askGuide = false; initSearchValues.localInfo = false;  goToSearchResults()">Local Experiences</button>
            </div>
            <div class="w-full flex border-2 border-32838C px-5 py-3 rounded-49px items-center justify-center mt-5"  ref="searchInput">
                <div class="max-w-160px">
                    <input type="text" class="w-full text-ellipsis placeholder:text-center bg-transparent px-0 py-0 text-1B3B3F text-15px focus-visible:outline-none" v-model="initSearchValues.location.name" @input="getLocationsVariants" placeholder="Where are you going?"/>
                </div>
                <div class="mx-3 h-30px w-1px bg-32838C"></div>
                <div>
                    <VueDatePicker multi-calendars :teleport="true" ref="datepicker" v-model="initSearchValues.dates"  range :partial-range="false" :enable-time-picker="false" placeholder="When?" :min-date="new Date()" @update:model-value="updateSearchState" ></VueDatePicker>
                </div>
                <div v-if="layout === 'default'">
                    <button type="button" class="p-2 bg-transparent border-none outline-none" @click="searchActionsFn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9167 6.75793C10.9167 9.05474 9.05474 10.9167 6.75793 10.9167C4.46113 10.9167 2.59921 9.05474 2.59921 6.75793C2.59921 4.46113 4.46113 2.59921 6.75793 2.59921C9.05474 2.59921 10.9167 4.46113 10.9167 6.75793ZM9.83425 12.7767C8.91152 13.2493 7.86587 13.5159 6.75793 13.5159C3.02563 13.5159 0 10.4902 0 6.75793C0 3.02563 3.02563 0 6.75793 0C10.4902 0 13.5159 3.02563 13.5159 6.75793C13.5159 8.44319 12.899 9.98437 11.8787 11.168L14.5031 14.2297C14.9702 14.7747 14.9071 15.5951 14.3621 16.0622C13.8171 16.5293 12.9967 16.4662 12.5296 15.9212L9.83425 12.7767Z" fill="#32838C"/>
                        </svg>
                    </button>
                </div>
                <div v-if="layout === 'default' && close">
                    <button type="button" class="p-2 bg-transparent border-none outline-none text-32838C transform translate-x-2" @click="minifySearch()">
                        <svg class="w-5 h-5" viewBox="0 0 24 24" id="meteor-icon-kit__solid-times-circle" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" clip-rule="evenodd" d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12 9.87596L8.81394 6.6899C8.2274 6.10337 7.27644 6.10337 6.6899 6.6899C6.10337 7.27644 6.10337 8.2274 6.6899 8.81394L9.87596 12L6.6899 15.1861C6.10337 15.7726 6.10337 16.7236 6.6899 17.3101C7.27644 17.8966 8.2274 17.8966 8.81394 17.3101L12 14.124L15.1861 17.3101C15.7726 17.8966 16.7236 17.8966 17.3101 17.3101C17.8966 16.7236 17.8966 15.7726 17.3101 15.1861L14.124 12L17.3101 8.81394C17.8966 8.2274 17.8966 7.27644 17.3101 6.6899C16.7236 6.10337 15.7726 6.10337 15.1861 6.6899L12 9.87596Z" fill="#32838C"></path></g></svg>
                    </button>
                </div>
            </div>
            <div class="flex w-full sm:-ml-50px sm:-mr-50px sm:w-[calc(100%_+_100px)] flex-col" v-if="layout === 'pills-layout'">
                <div v-show="showActions" class="pt-5 text-center font-semibold text-xl text-32838C"><span class="p-0">What are you looking for?</span></div>
                <div v-show="showActions" class=" mt-2 grid grid-cols-1 md:grid-cols-3 gap-3">
                    <button type="button" class="px-1 py-2 border-2 rounded-30px text-32838C border-32838C justify-center text-xs font-semibold bg-none hover:bg-32838C hover:text-white" :class="{'bg-32838Cw !text-whitew': initSearchValues.askGuide}" @click.stop="initSearchValues.askGuide=true; initSearchValues.localInfo = false; initSearchValues.experiences = false;  goToSearchResults()">Local Guide</button>
                    <button type="button" class="px-1 py-2 border-2 rounded-30px text-32838C border-32838C justify-center text-xs font-semibold bg-none hover:bg-32838C hover:text-white" :class="{'bg-32838Cw !text-whitew': initSearchValues.localInfo}" @click.stop="initSearchValues.localInfo = true; initSearchValues.askGuide = false; initSearchValues.experiences = false;  goToSearchResults()">Local Recommendations</button>
                    <button type="button" class="px-1 py-2 border-2 rounded-30px text-32838C border-32838C justify-center text-xs font-semibold bg-none hover:bg-32838C hover:text-white" :class="{'bg-32838Cw !text-whitew': initSearchValues.experiences}" @click.stop="initSearchValues.experiences = true; initSearchValues.askGuide = false; initSearchValues.localInfo = false;  goToSearchResults()">Local Experiences</button>
                </div>
            </div>
            <div v-if="initSearchValues.showLocations" class="absolute top-full bg-EFFDFFE3 py-5 px-1 rounded-20px backdrop-blur-lg max-w-579px w-full shadow-2xl max-h-400px overflow-auto" v-click-outside="clickOutsideLocations" ref="searchResults">
                <div v-for="(location, index) in initSearchValues.locations" :key="index">
                    <div class="w-full flex items-center p-3 hover:bg-[#efefef] group/location transition-all duration-300 ease-in-out rounded-10px cursor-pointer" @click="setLocation(location)">
                        <div class="shrink-0">
                            <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.36133 12.0187C9.36133 9.33546 11.8454 7.16669 14.8502 7.16669C17.8634 7.16669 20.3475 9.33546 20.3475 12.0187C20.3475 13.3708 19.8028 14.6261 18.9061 15.6901C17.9169 16.8637 16.6976 17.8863 15.3253 18.6889C15.0112 18.8744 14.7277 18.8884 14.383 18.6889C13.0028 17.8863 11.7835 16.8637 10.8028 15.6901C9.90546 14.6261 9.36133 13.3708 9.36133 12.0187ZM13.0412 12.1698C13.0412 13.0687 13.8538 13.7757 14.8502 13.7757C15.8472 13.7757 16.6677 13.0687 16.6677 12.1698C16.6677 11.2779 15.8472 10.5365 14.8502 10.5365C13.8538 10.5365 13.0412 11.2779 13.0412 12.1698Z" fill="#666666"/>
                            </svg>
                        </div>
                        <div class="flex flex-col px-2">
                            <h5 class="text-base text-black font-bold">{{ location.name }}</h5>
                            <p class="m-0 text-B1B1B1 text-base group-hover/location:text-[#666666]">{{ location.path }}</p>
                        </div>
                        <div class="hidden ml-auto">
                            <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.7187 7.29289C13.0792 6.93241 13.6464 6.90468 14.0387 7.2097L14.1329 7.29289L20.1329 13.2929C20.4934 13.6534 20.5211 14.2206 20.2161 14.6129L20.1329 14.7071L14.1329 20.7071C13.7424 21.0976 13.1092 21.0976 12.7187 20.7071C12.3582 20.3466 12.3305 19.7794 12.6355 19.3871L12.7187 19.2929L18.0108 14L12.7187 8.70711C12.3582 8.34662 12.3305 7.77939 12.6355 7.3871L12.7187 7.29289Z" fill="#343724"/>
                            <rect x="1.41602" y="0.5" width="30.0199" height="27" rx="13.5" stroke="#32838C"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </Transition>
</template>
<style scoped>
.search-enter-active,
.search-leave-active {
  transition: opacity 0.5s ease;
}

.search-enter-from,
.search-leave-to {
  opacity: 0;
}
</style>
<script>
import vClickOutside from "click-outside-vue3"
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import Popper from 'popper.js'
import moment from 'moment'
import { mapGetters, mapActions, } from 'vuex'

export default {
    directives: {
      clickOutside: vClickOutside.directive
    },
    
    expose: ['goToSearchResults'],
    metaInfo() {
        return {

        }
    },
    beforeMount() {
        window.addEventListener('scroll', this.handleScroll)
        this.setSearchType()
        this.$store.dispatch('detectDevice');
    },
    watch:{
        $route (to, from){
            this.showForm = false
            this.setSearchType()
        },
    },
    props: {
        expanded: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        layout:{
            type:String,
            default:'default'
        },
        bgForm:{
            format: String,
            default:'bg-EFFDFF'
        },
        close: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            popperInstance: null
        }
    },
    computed:{
        ...mapGetters(['expandSearch','getSearch','isMobile']),
        initSearchValues(){ 
            return this.getSearch
        },
        whereLabel(){
            return this.initSearchValues.location.name !== "" ? this.initSearchValues.location.name : 'Where are you going?'
        },
        whenLabel(){
            return this.initSearchValues.dates ? this.formatLabelDates() : 'When?'
        },
        showForm(){
            return this.expandSearch
        },
        showActions(){
            let result = false
            if(this.initSearchValues.dates && this.layout === 'pills-layout' && this.initSearchValues.location.id){
                result = true
            }
            return result
        },
        isThisMobile() {
            return this.isMobile;
        },
    },
    methods: {
        ...mapActions(['expandSearch','getLocations']),
        ...mapGetters(['getSearch']),

        resetFormSize(event){
            this.$store.dispatch('expandSearch', false)
        },
        middleware (event) {
            return event.target.className !== 'expandSize'
        },
        setHeaderClass(){
            this.$store.dispatch('expandSearch', true)
        },
        handleScroll(){
            if(0 < window.scrollY < 200){
                this.minifySearch()
            }
        },
        minifySearch(){
            this.$store.dispatch('expandSearch', false)
            this.initSearchValues.showLocations = false
            this.$refs.datepicker.closeMenu()
            this.destroyPopper()
        },
        openCalendar(){
            setTimeout(() => {
                this.$refs.datepicker.openMenu();
            }, "400");

            
        },
        getLocationsVariants(){
            const txt = this.initSearchValues.location.name.trim();
            if(txt.length === 0){
                delete this.initSearchValues.location.id
                this.initSearchValues.showLocations = false
                this.destroyPopper()
            }
            if (txt.length > 3){
                this.initSearchValues.showLocations = true
                this.getLocations({t: txt}).then((locations) => {
                    this.initSearchValues.locations = locations
                    
                    if(this.initSearchValues.showLocations && this.initSearchValues.locations.length){
                        this.initPopper()
                    }
                    
                })
            }
        },
        clickOutsideLocations(){
            if(this.isThisMobile){
                return
            }
            this.hideLocations()
        },
        hideLocations(){
            this.initSearchValues.showLocations = false
            this.destroyPopper()
        },
        setLocation(location){
            this.initSearchValues.location = location
            this.updateSearchState()
            this.hideLocations()
        },
        searchActionsFn(){
            if(this.showForm){
                console.log(this.initSearchValues)
                if(this.initSearchValues.askGuide === false && this.initSearchValues.localInfo === false && this.initSearchValues.experiences === false){
                   
                    this.initSearchValues.askGuide = true
                }
                this.updateSearchState()
                this.goToSearchResults()
            } else {
                this.showForm = true
                this.setHeaderClass()
            }
            
        },
        goToSearchResults(){
            if(this.initSearchValues.location.id && this.initSearchValues){
                const startDate = moment(this.initSearchValues.dates[0]).format("YYYY-MM-DD")
                const endDate = moment(this.initSearchValues.dates[1]).format("YYYY-MM-DD")
                this.updateSearchState()
                if(this.initSearchValues.askGuide){
                    this.$router.push({ name: 'SearchGuides', query: { lid: this.initSearchValues.location.id, start_date: startDate, end_date: endDate}})
                }
                if(this.initSearchValues.localInfo){
                    this.$router.push({ name: 'SearchRecommendations', query: { lid: this.initSearchValues.location.id, start_date: startDate, end_date: endDate}})
                }
                if(this.initSearchValues.experiences){
                    this.$router.push({ name: 'SearchExperiences', query: { lid: this.initSearchValues.location.id, start_date: startDate, end_date: endDate}})
                }
            }
        },
        setSearchType(){
            const routerName = this.$route.name
            if(routerName === 'SearchGuides'){
                this.initSearchValues.askGuide = true
                this.initSearchValues.localInfo = false
                this.initSearchValues.experiences = false
            }
            if(routerName === 'SearchRecommendations'){
                this.initSearchValues.askGuide = false
                this.initSearchValues.localInfo = true
                this.initSearchValues.experiences = false
            }
            if(routerName === 'SearchExperiences' ){
                this.initSearchValues.askGuide = false
                this.initSearchValues.localInfo = false 
                this.initSearchValues.experiences = true
            }
        },
        resetExtend(){
            this.expanded = false
        },
        formatLabelDates(){
            let startDate = moment(this.initSearchValues.dates[0]).format('MMM D')
            let endDate = moment(this.initSearchValues.dates[1]).format('MMM D')
            if(moment(this.initSearchValues.dates[0]).isSame(this.initSearchValues.dates[1], 'month')){
                startDate = moment(this.initSearchValues.dates[0]).format('MMM D');
                endDate = moment(this.initSearchValues.dates[1]).format('D');
            }
            const output = `${startDate} - ${endDate}`
            return output
        },
        updateSearchState(){
            this.$store.commit('setSearch', this.initSearchValues)
        },
        initPopper() {
            const reference = this.$refs.searchInput; // Reference element (e.g., button)
            const popover = this.$refs.searchResults // Popover element

            this.popperInstance = new Popper(reference, popover, {
                placement: 'auto', // Set your desired placement
                modifiers: {
                preventOverflow: {
                    boundariesElement: 'viewport', // Ensure the popover stays within the viewport
                },
                },
            })
        },
        destroyPopper() {
            if (this.popperInstance) {
                this.popperInstance.destroy();
                this.popperInstance = null;
            }
        },
    },
    components: {
        VueDatePicker
    },
    beforeUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    },
}

</script>