// import Vue from 'vue'
import * as types from '@/store/mutation-types.js'
import api from '@/services/api/profile'
import { buildSuccess, handleError } from '@/utils/utils.js'

const getters = {

}

const actions = {
    getProfile({commit}, payload){
        return new Promise((resolve, reject) => {
            api.getProfile(payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    putProfile({commit}, payload){
        return new Promise((resolve, reject) => {
            api.putProfile(payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
}

const state = {
    profile: null,
}

const mutations = {
    setProfile(state, value) {
        state.profile = value
    },
}


export default {
    state,
    getters,
    actions,
    mutations,
}