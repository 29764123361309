import axios from 'axios'

export default {
    getGuideQuizData(payload) {
        return axios.get(`/api/v1/new-guide/quiz${payload.guide_area_id ? '?guide_area_id='+payload.guide_area_id : ''}`)
    },
    submitGuideQuiz(payload) {
        axios.defaults.headers.common['Content-Type'] ='application/json'
        return axios.post(`/api/v1/guide-area/save`, payload)
    },
    submitGuideQuizForReview(areaId) {
        return axios.post(`/api/v1/guide-area/${areaId}/review-ready`)
    },
    getGuideMyExpertAreas()
    {
        return axios.get(`/api/v1/guide-areas`)
    },
    getMyExpertArea(areaId)
    {
        return axios.get(`/api/v1/guide-areas/${areaId}`)
    },
    deleteGuideExpertArea(areaId)
    {
        return axios.delete(`/api/v1/guide-areas/${areaId}`)
    }
}