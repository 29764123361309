export default [
    /* GENERAL PAGES */
    {
        path: '/my-profile',
        name: 'ViewMyProfile',
        meta: {requiresAuth: true},
        component: () =>
            import(
                /* webpackChunkName: "logged" */ '@/components/pages/MyProfilePage.vue'
            )
    },

    /* USER SPECIFIC PAGES */
    {
        path: '/my-travels',
        name: 'MyTravels',
        meta: {requiresAuth: true},
        component: () =>
            import(
                /* webpackChunkName: "loggedUser" */ '@/components/pages/MyTravels.vue'
            )
    },


    /* GUIDE SPECIFC PAGES */
    {
        path: '/my-expert-areas',
        name: 'MyExpertAreas',
        meta: {requiresAuth: true},
        component: () =>
            import(
                /* webpackChunkName: "loggedGuide" */ '@/components/pages/MyExpertAreas.vue'
            )
    },
    {
        path: '/start-quiz',
        name: 'NewGuideExpertArea',
        meta: {requiresAuth: true},
        component: () =>
            import(
                /* webpackChunkName: "loggedGuide" */ '@/components/pages/NewExpertAreaQuiz.vue'
            )
    },
    {
        path: '/start-quiz/:guideAreaId',
        meta: {requiresAuth: true},
        component: () =>
            import(
                /* webpackChunkName: "loggedGuide" */ '@/components/pages/NewExpertAreaQuiz.vue'
            )
    },
    {
        path: '/my-earnings',
        name: 'MyEarnings',
        meta: {requiresAuth: true},
        component: () =>
            import(
                /* webpackChunkName: "loggedGuide" */ '@/components/pages/MyEarnings.vue'
            )
    },
    {
        path: '/training',
        name: 'Training',
        meta: {requiresAuth: true},
        component: () =>
            import(
                /* webpackChunkName: "loggedGuide" */ '@/components/pages/Training.vue'
            )
    },
    {
        path: '/training/:trainingSlug',
        name: 'TrainingPage',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "loggedGuide" */ '@/components/pages/TrainingPage.vue'
            )
    },
    {
        path: '/orders',
        name: 'Orders',
        meta: {requiresAuth: true},
        component: () =>
            import(
                /* webpackChunkName: "loggedGuide" */ '@/components/pages/Orders.vue'
            )
    },
    {
        path: '/new-experience',
        name: 'NewExperience',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "loggedGuide" */ '@/components/pages/NewExperience.vue'
            )
    },
    {
        path: '/edit-experience/:id',
        name: 'EditExperience',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "loggedGuide" */ '@/components/pages/NewExperience.vue'
            )
    },
    {
        path: '/my-experiences',
        name: 'MyExperiencesGuide',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "loggedGuide" */ '@/components/pages/MyExperiencesGuidePage.vue'
            )
    },
    {
        path: '/travel-plan-builder/:guideAreaId',
        name: 'TravelPlanBuilder',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "loggedGuide" */ '@/components/pages/TravelPlanBuilder.vue'
            )
    }
]
