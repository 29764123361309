import { createRouter, createWebHistory } from 'vue-router'
import {store} from "@/store";
import Meta from 'vue-meta'
import routes from '@/router/routes'
import * as types from '@/store/mutation-types'
import Cookies from 'js-cookie'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },
    routes
})

router.beforeEach((to, from, next) => {
    let existingToken = Cookies.get('authToken', {path: '/'})

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated || (!store.getters.isAuthenticated && (existingToken && existingToken != 'undefined'))) {
            next()
            return
        }
        next({'name': 'Login', state: { loginRedTo: to.fullPath}})
    } else {
        next()
    }
})

export default router
