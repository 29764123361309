export default [
    {
        path: '/',
        name: 'publicDashboard',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/pages/Dashboard.vue'
            )
    },
    {
        path: '/become-a-guide',
        name: 'BecomeGuide',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/pages/BecomeLocalGuidePage.vue'
            )
    },
    {
        path: '/guide/:id',
        name: 'GuideProfile',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/pages/GuideProfilePage.vue'
            )
    },
    {
        path: '/guide/:id/consulation-call',
        name: 'GuidePurchaseCall',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/pages/GuidePurchaseCall.vue'
            )
    },
    {
        path: '/guide/:id/local-guide',
        name: 'GuidePurchaseLocalGuide',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/pages/GuidePurchaseLocalGuide.vue'
            )
    },
    {
        path: '/guide/:id/local-recommendations',
        name: 'GuidePurchaseLocalRecommendations',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/pages/GuidePurchaseRecommendations.vue'
            )
    },
    {
        path: '/experience/:id',
        name: 'ExperiencePage',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/pages/ExperiencePage.vue'
            )
    },
    {
        path: '/local-guides',
        name: 'SearchGuides',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/pages/LocalGuidesPage.vue'
            )
    },
    {
        path: '/login',
        name: 'Login',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/pages/LoginPage.vue'
            )
    },
    {
        path: '/app/event/logout',
        name: 'iOSlogout',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/pages/iOSLogout.vue'
            )
    },
    {
        path: '/app/event/settings',
        name: 'iOSsettings',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/pages/iOSSettings.vue'
            )
    },
    {
        path: '/local-recommendations',
        name: 'SearchRecommendations',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/pages/LocalRecommendationsPage.vue'
            )
    },
    {
        path: '/local-experiences',
        name: 'SearchExperiences',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/pages/LocalExperiencesPage.vue'
            )
    },
    {
        path: '/legal',
        name: 'GeneralLegal',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/GeneralLegalPage.vue'
            )
    },
    {
        path: '/terms-of-use',
        name: 'PublicTermsOfUse',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/PublicTermsPage.vue'
            )
    },
    {
        path: '/privacy-policy',
        name: 'PublicPrivacyPolicy',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/PublicPrivacyPolicyPage.vue'
            )
    },
    {
        path: '/cookie-policy',
        name: 'PublicCookiePolicy',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/PublicCookiePolicyPage.vue'
            )
    },
    {
        path: '/disclaimer',
        name: 'PublicDisclaimer',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/PublicDisclaimerPage.vue'
            )
    },
    {
        path: '/intellectual-property-policy',
        name: 'PublicIntellectualPropertyPolicy',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/PublicIntellectualPropertyPolicyPage.vue'
            )
    },
    {
        path: '/accessibility-statement',
        name: 'PublicAccessibilityStatement',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/PublicAccessibilityStatementPage.vue'
            )
    },
    {
        path: '/user-agreement',
        name: 'TravelerUserAgreement',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/TravelerUserAgreementPage.vue'
            )
    },
    {
        path: '/safety-guidelines',
        name: 'TravelerSafetyGuidelines',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/TravelerSafetyGuidelinesPage.vue'
            )
    },
    {
        path: '/data-usage-and-privacy',
        name: 'TravelerDataUsagePrivacy',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/TravelerDataUsagePage.vue'
            )
    },
    {
        path: '/dispute-resolution-policy',
        name: 'TravelerDisputeResolutionPolicy',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/TravelerDisputeResolutionPage.vue'
            )
    },
    {
        path: '/feedback-and-reviews-policy',
        name: 'TravelerFeedbackReviewsPolicy',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/TravelerFeedbackReviewsPage.vue'
            )
    },
    {
        path: '/guide-agreement',
        name: 'GuideAgreement',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/GuideAgreementPage.vue'
            )
    },
    {
        path: '/content-guidelines',
        name: 'GuideContentGuidelines',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/GuideContentGuidelinesPage.vue'
            )
    },
    {
        path: '/conduct-and-ethics-policy',
        name: 'GuideConductEthicsPolicy',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/GuideConductEthicsPage.vue'
            )
    },
    {
        path: '/insurance-and-liability',
        name: 'GuideInsuranceLiability',
        meta: {},
        component: () =>
            import(
                /* webpackChunkName: "public" */ '@/components/legal/GuideInsuranceLiabilityPage.vue'
            )
    },
]

