// import Vue from 'vue'
import * as types from '@/store/mutation-types.js'
import router from '@/router'
import {useRoute} from 'vue-router'
import api from '@/services/api/auth'
import { buildSuccess, handleError } from '@/utils/utils.js'
import Cookies from 'js-cookie'

const getters = {
    isAuthenticated: state => !!state.user,
    publicPage: state => true ,
    user: (state) => state.user,
    authToken: state => state.authToken,
    refreshToken: state => state.refreshToken,
    isAdmin: state => state.user && state.user.is_admin,
}

const actions = {
    accountCheck({commit}, payload){
        return new Promise((resolve, reject) => {
            api.accountCheck(payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    accountLogin({commit}, payload){
        return new Promise((resolve, reject) => {
            api.accountLogin(payload)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        Cookies.set('authToken', response.data.tokens.auth, {expires: 7, path: '/'})
                        commit('setAuthToken', response.data.tokens.auth)
                        Cookies.set('refreshToken', response.data.tokens.refresh, {expires: 14, path: '/'})
                        commit('setRefreshToken', response.data.tokens.refresh)
                        this.dispatch('fetchUser')
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    accountCreate({commit}, payload){
        return new Promise((resolve, reject) => {
            api.accountCreate(payload)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        Cookies.set('authToken', response.data.tokens.auth, {expires: 7, path: '/'})
                        commit('setAuthToken', response.data.tokens.auth)
                        Cookies.set('refreshToken', response.data.tokens.refresh, {expires: 14, path: '/'})
                        commit('setRefreshToken', response.data.tokens.refresh)
                        this.dispatch('fetchUser')
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    refreshTokens({commit}) {
        return new Promise((resolve, reject) => {
            api.refreshToken()
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        Cookies.set('authToken', response.data.data.tokens.auth, {expires: 7, path: '/'})
                        commit('setAuthToken', response.data.data.tokens.auth)

                        Cookies.set('refreshToken', response.data.data.tokens.refresh, {expires: 14, path: '/'})
                        commit('setRefreshToken', response.data.data.tokens.refresh)

                        this.dispatch('fetchUser').then((response2) => {
                            resolve(response.data)
                        })
                    }
                })
                .catch((error) => {
                    commit(types.LOGOUT)
                    router.push('/login')
                })
        })
    },
    fetchUser({commit}) {
        const authToken = Cookies.get('authToken', {path: '/'})

        if (!authToken || authToken === 'undefined') {
            return
        }

        return new Promise((resolve, reject) => {
            api.fetchUser()
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        commit(types.SAVE_USER, response.data.account)
                        resolve(response.data.account)
                    }
                })
                .catch((error) => {
                    // error, leave to app?
                })
        })
    },
    checkAuthCookies({commit}) {
        if (window.appAuthToken && window.appAuthToken != '') {
            Cookies.set('authToken', window.appAuthToken, {expires: 7, path: '/'})
            Cookies.set('refreshToken', '', {expires: 7, path: '/'})
        }

        const authToken = Cookies.get('authToken', {path: '/'})
        const refreshToken = Cookies.get('refreshToken', {path: '/'})

        if (!authToken || authToken === 'undefined') {
            commit('setAuthToken', null)
            commit('setRefreshToken', null)
            return
        }

        commit('setAuthToken', authToken)
        commit('setRefreshToken', refreshToken)

        this.dispatch('fetchUser')
    },
    userLogout({ commit, rootState }) {
        const logoutMobileApp = rootState.app.isIosApp 
        commit(types.LOGOUT)
        if (logoutMobileApp) {
            router.push({
                name: 'iOSlogout'
            })
            return
        } 
        router.push({
            name: 'publicDashboard'
        })
       
    }
}

const state = {
    user: null,
    authToken: "",
    refreshToken: "",
}

const mutations = {
    [types.LOGOUT](state) {
        state.user = null
        state.authToken = ''
        state.refreshToken = ''
        Cookies.remove('authToken', {path: '/'})
        Cookies.remove('refreshToken', {path: '/'})
    },
    [types.SAVE_USER](state, user) {
        state.user = user
    },
    setRefreshToken(state, token) {
        state.refreshToken = token
    },
    setAuthToken(state, token) {
        state.authToken = token
    }
}


export default {
    state,
    getters,
    actions,
    mutations,
}