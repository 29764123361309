import * as types from '@/store/mutation-types'
import packageJson from '../../../../package.json'

const getters = {
    appTitle: (state) => state.appTitle,
    showHeaderSearch:  (state) => state.showHeaderSearch,
    noHeader:  (state) => state.noHeader,
    noFooter:  (state) => state.noFooter,
    expandSearch:  (state) => state.expandSearch,
    noMainNav: (state) => state.noMainNav,
    showApp: (state) => state.showApp,
    isMobile: (state) => state.isMobile,
    isIosApp: (state) => state.isIosApp,
  }

  const actions = {
    setAppTitle({ commit }, payload) {
      commit(types.SET_APP_TITLE, payload)
    },
    showHeaderSearch({ commit }, value) {
      commit('showHeaderSearch', value)
    },
    expandSearch({ commit }, value) {
        commit('expandSearch', value)
    },
    noHeader({ commit }, value) {
      commit('noHeader', value)
    },
    noFooter({ commit }, value) {
      commit('noFooter', value)
    },
    noMainNav({commit}, value) {
      commit('noMainNav', value)
    },
    showApp({commit}, value) {
      commit('showApp', value)
    },
    detectDevice({ commit }) {
      if (window.isIosApp) {
        commit('setIsIosApp', true)
        commit('noFooter', true)
        commit('SET_IS_MOBILE', true);
        return
      }

      const userAgent = navigator.userAgent.toLowerCase()
      const isMobile =  /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent)
      commit('SET_IS_MOBILE', isMobile);
    },
  }

  const mutations = {
    [types.SET_APP_VERSION](state, version) {
      state.appVersion = version
    },
    [types.SET_APP_TITLE](state, title) {
      state.appTitle = title
    },
    showHeaderSearch(state, value) {
      state.showHeaderSearch = value
    },
    expandSearch(state, value) {
        state.expandSearch = value
    },
    noHeader(state, value) {
      state.noHeader = value
    },
    noFooter(state, value) {
      state.noFooter = value
    },
    noMainNav(state, value) {
      state.noMainNav = value
    },
    showApp(state, value) {
      state.showApp = value
    },
    SET_IS_MOBILE(state, value) {
      state.isMobile = value;
    },
    setIsIosApp(state, value) {
      state.isIosApp = value;
    }
  }

  const state = {
    appTitle: 'YouGo',
    appVersion: packageJson.version,
    showHeaderSearch: true,
    expandSearch: false,
    noHeader: false,
    noFooter: false,
    noMainNav: false,
    showApp: false,
    isMobile: false,
    isIosApp: false,
  }

  export default {
    state,
    getters,
    actions,
    mutations
  }
