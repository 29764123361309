// app
export const SET_APP_VERSION = 'SET_APP_VERSION'
export const SET_APP_TITLE = 'SET_APP_TITLE'
// locale
export const SET_LOCALE = 'SET_LOCALE'
// error
export const SHOW_ERROR = 'SHOW_ERROR'
export const ERROR = 'ERROR'
// success
export const SHOW_SUCCESS = 'SHOW_SUCCESS'
export const SUCCESS = 'SUCCESS'
// loading
export const SHOW_LOADING = 'SHOW_LOADING'
// auth
export const IS_LOGGED_IN = 'IS_LOGGED_IN'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const SAVE_USER = 'SAVE_USER'
export const LOGOUT = 'LOGOUT'
// profile
export const FILL_PROFILE = 'FILL_PROFILE'
export const ADD_PROFILE_DATA = 'ADD_PROFILE_DATA'
// product
export const ASSESSMENT = 'ASSESSMENT'
export const PRODUCT = 'PRODUCT'