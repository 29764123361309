import axios from 'axios'
import { store } from '@/store'
import router from "@/router";
import Cookies from 'js-cookie'

if (window.appConfigs === undefined) {
    window.appConfigs = envConfigs
}

axios.defaults.withCredentials = false
axios.defaults.baseURL = window.appConfigs.API_URL
// axios.defaults.headers.common['Accept-Language'] = JSON.parse(localStorage.getItem('locale')) || 'en'
axios.defaults.headers.common['Content-Type'] ='application/json'

axios.interceptors.request.use(
     (config) => {
        let token = Cookies.get('authToken', {path: '/'})

        if (config.url === "/api/v2/account/auth/refresh-tokens") {
            token = Cookies.get('refreshToken', {path: '/'})
        }

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config
     },
     (error) => {
         return Promise.reject(error)
     }
 )
 axios.interceptors.response.use(
     (response) => {
         return response
     },
     (error) => {
        let origRequest = error.config

        const status = error.response ? error.response.status : null

        if (status === 401 && origRequest.url != "/api/v2/account/auth/refresh-tokens") {
            store.dispatch('refreshTokens').then((response) => {
                origRequest.headers.Authorization = `Bearer ${Cookies.get('authToken', {path: '/'})}`;
                return Promise.resolve(true)
            })
        }

        if (status === 401 && origRequest.url === "/api/v2/account/auth/refresh-tokens") {
            router.push('/login')
        }

        return Promise.reject(error)
     }
 )

export default Vue => Vue.provide('$axios', axios)
