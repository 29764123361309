import axios from 'axios'

export default {
    getLocations(payload) {
        return axios.get(`api/locations/search/v1?t=${payload.t}`)
    },
    getLocalGuides(payload) {
        const page = payload.page > 1 ? `&page=${payload.page}` : ''
        return axios.get(`/api/locations/virtual-guides/v1?lid=${payload.lid}&start_date=${payload.start_date}&end_date=${payload.end_date}${page}`)
    },
    getLocalRecommedations(payload) {
        const page = payload.page > 1 ? `&page=${payload.page}` : ''
        return axios.get(`/api/locations/guides/v1?lid=${payload.lid}&start_date=${payload.start_date}&end_date=${payload.end_date}${page}`)
    },
    getLocalExperiences(payload) {
        return axios.get(`/api/experiences/area-search/v1`,{params:payload})
    },
    getLocation(id){
        return axios.get(`/api/locations/search/v1/${id}`)
    }
}