<template>
    <div class="w-screen h-screen bg-white text-black flex justify-center grid content-center absolute top-0"
        style="z-index: 2000"
        v-if="!showApp()"
    >
        <div class="logo w-[200px] relative overflow-hidden h-[50px] cursor-pointer">
            <router-link to="/"><img src="/images/yougo-logo.svg"/></router-link>
        </div>
    </div>
    <Header v-if="!isThisMobile"></Header>
    <HeaderMobile v-if="isThisMobile"></HeaderMobile>
    <router-view></router-view>
    <MobileNav v-if="isThisMobile"></MobileNav>
    <Footer></Footer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { computed, watch } from 'vue'

export default {
    name: "YouGoApp",
    metaInfo() {
        return {
        }
    },
    beforeMount() {
        this.$store.dispatch('detectDevice');
     },
    mounted() {
        this.checkAuthCookies()

        if (!this.authToken()) {
            this.$store.dispatch('showApp', true)
            return
        }

        watch(this.user, async(newUserVal, oldUserVal) => {
            if (!this.authToken()) {
                this.$store.dispatch('showApp', true)
                return
            }

            if (this.authToken() && newUserVal) {
                this.$store.dispatch('showApp', true)
                // if (newUserVal.force_quiz && !this.$route.fullPath.startsWith('/start-quiz')) {
                //     this.$router.push({path: '/start-quiz'})
                // } else {
                //     this.$store.dispatch('showApp', true)
                // }
            }
        })

    },
    data() {
        return {

        }
    },
    methods: {
        ...mapActions(['checkAuthCookies']),
        ...mapGetters(['authToken', 'user', 'showApp'])

    },
    computed:{
        ...mapGetters(['isMobile']),
        isThisMobile() {
            return this.isMobile && this.$route.name !== 'iOSlogout'
        },
    },
    components: {
    },
    // watch: {
    //     'authToken' (newAt, oldAt) {
    //         console.log('APP USER', newUserInfo)
    //     },
    //     'user' (newUserInfo, oldUserInfo) {
    //         console.log('APP USER', newUserInfo)
    //         // if (newUserInfo.force_quiz && !this.$route.fullPath.startsWith('/start-quiz')) {
    //         //     this.$router.push({path: '/start-quiz'})
    //         // }
    //     }
    // }
}
</script>


